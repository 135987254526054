import flatpickr from 'flatpickr'
import { Controller } from '@hotwired/stimulus'

export default class DateTimeController extends Controller {
  static values = {
    enableTime: {
      default: true,
      type: Boolean,
    },
    min: String,
    max: String,

    // enabledDays is an array of integers representing the days of the week.
    // Sunday = 0, Monday = 1, etc.
    // If the array contains -1 (default), all days are enabled.
    enabledDays: {
      type: Array,
      optional: true,
      default: [-1],
    },
  }

  connect() {
    flatpickr(this.element, {
      enableTime: this.enableTimeValue,
      dateFormat: this.enableTimeValue ? 'Y-m-d H:i' : 'Y-m-d',
      time_24hr: true,
      defaultDate: this.element.value,
      minDate: this.hasMinValue && this.minValue,
      maxDate: this.hasMaxValue && this.maxValue,
      onChange: (selectedDates, dateStr, _instance) => {
        this.element.value = dateStr
      },
      enable: [
        (date) => {
          if (!this.hasEnabledDaysValue || this.enabledDaysValue.includes(-1)) {
            return true
          }
          return this.enabledDaysValue.includes(date.getDay())
        },
      ],
    })
  }
}
