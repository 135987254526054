import { Application } from '@hotwired/stimulus'
import Sortable from 'stimulus-sortable'

const application = Application.start()

// Third party controllers
application.register('sortable', Sortable)

// Configure Stimulus development experience
application.debug = false
window.Stimulus = application

export { application }
