import { Controller } from '@hotwired/stimulus'
import { put } from '@rails/request.js'
import jstz from 'jstz'

/**
 * Updates the user's timezone if it's different from the one provided
 */
export default class TimeZoneController extends Controller {
  static values = {
    tz: String,
    updateUrl: String,
  }

  async connect() {
    const timezone = jstz.determine()
    if (timezone.name() === this.tzValue) {
      return
    }

    await put(this.updateUrlValue, {
      body: {
        user: {
          time_zone: timezone.name(),
        },
      },
      contentType: 'application/json',
    })
  }
}
